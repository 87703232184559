<template>
  <b-form-group
    label="Proje"
    label-for="id_com_realty_project"
  >
    <v-select
      id="id_com_realty_project"
      v-model="filterData.id_com_realty_project"
      placeholder="Proje"
      :options="projects"
      label="title"
      :reduce="project => project.id"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    projects() {
      return this.$store.getters['realtyProjects/getRealty_sale_projects']
    },
  },
  created() {
    this.getProjects()
  },
  methods: {
    getProjects() {
      const params = {
        select: [
          'com_realty_project.id AS id',
          'com_realty_project.title AS title',
        ],
      }
      this.$store.dispatch('realtyProjects/realty_sale_projectsList', params)
    },
  },
}
</script>

<style scoped>

</style>
